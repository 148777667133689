import { useState } from "react";
import InfoDropdown from "../components/InfoDropdown";
import Splash from "../components/Splash";
import liftTruckImg from "../images/extra-pic.jpg"
import bgImg from "../images/service-banner.jpg"
import liftLogo from "../images/logo-lift-white.png"
import service1 from "../images/service/service_1.jpg"
import service2 from "../images/service/service_2.jpg"
import service3 from "../images/service/service_3.jpg"
import service4 from "../images/service/service_4.jpg"

function RepairsAndServicePage() {

    const [activeDropdown, setActiveDropdown] = useState(null);

    const text = (
        <div>
            <img src={liftLogo} className="w-1/3 md:w-2/3" />
            <h1 className="text-4xl md:text-6xl uppercase text-white font-bold pb-2 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] md:drop-shadow-none">Repairs<br />And<br />Service</h1>
            <p className="text-2xl md:text-3xl text-white drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] md:drop-shadow-none">We've got you covered.</p>
        </div>
    );

    const descriptions = {
        one: "Our fleet of mobile repair vans covers all of Atlantic Canada. We know where our technicians are, so we can get to you and your material handling equipment faster.",
        two: "We are committed to perform the highest level of service and repair on your forklift or other material handling equipment. We are so confident in our ability we’ve created a Performance Guarantee for our customers.",
        three: "For fleet users we can lower your operating costs, track your service costs, and provide monthly or quarterly reporting, including per-hour cost per truck. In addition, never have a forklift or other lift truck down with our loaner program with guaranteed response time and no-hassle maintenance program."
    };

    const titles = {
        one: "GPS Dispatched Service",
        two: "Performance Guarantee",
        three: "Fleet Maintenance Program"
    };

    const handleClick = (key) => {
        setActiveDropdown(activeDropdown === key ? null : key);
    }

    return (
        <div>
            <div>
                <Splash image={bgImg} text={text} />
            </div>
            <div className="mx-auto my-10 flex flex-col md:flex-row columns-2 gap-5 items-start justify-center w-4/5 md:w-3/4">
                {/* <img src={liftTruckImg} className="w-full md:w-1/2" /> */}
                <div className="w-full flex flex-col gap-5">
                    {/* <div className="flex flex-col gap-5">
                        {Object.keys(titles).map(key => (
                            <InfoDropdown
                                key={key}
                                title={titles[key]}
                                description={descriptions[key]}
                                isOpen={activeDropdown === key}
                                onClick={() => handleClick(key)}
                            />
                        ))}
                    </div> */}
                    <div className="flex flex-wrap md:flex-nowrap gap-5">
                        <div className="md:w-2/3 flex items-center justify-center">
                            <img className="object-contain w-full h-auto" src={service1} alt="Service image 1" />
                        </div>
                        <div className="md:w-1/3 flex flex-col justify-center">
                            <p className="pb-3">
                                <h3 className="font-black text-xl">Comprehensive Mobile Repair Services Across Atlantic Canada</h3>
                            </p>
                            <p className="pb-3">
                                At Hurricane Lift Truck, our extensive fleet of mobile repair vans is strategically deployed to cover all of Atlantic Canada. Our advanced tracking system allows us to monitor the precise location of our technicians in real-time. This capability ensures that we can quickly dispatch a technician to your site, providing prompt and efficient service when you need it most—especially in unexpected situations.
                                We take pride in our commitment to delivering the highest level of service and repair for your forklift and other material-handling equipment. Our technicians are highly trained and equipped with the latest tools and technology to address any issue swiftly and effectively. Whether it's routine maintenance or emergency repairs, you can rely on us to keep your equipment operating at peak performance, minimizing downtime and maximizing productivity.
                                Our dedication to excellence ensures that your material-handling operations run smoothly, allowing you to focus on your core business activities without worrying about equipment reliability. With Hurricane Lift Truck, you get not only the best service in the industry but also the peace of mind that comes with knowing your equipment is in expert hands.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-wrap md:flex-nowrap gap-5">
                        <div className="md:w-1/3 flex flex-col justify-center">
                            <p className="pb-3">
                                <h3 className="font-black text-xl">Service Plans</h3>
                            </p>
                            <p className="pb-1">
                                <h2 className="font-semibold text-lg">Essential Maintenance</h2>
                                Maintain the safety and efficiency of your equipment effortlessly with our Essential Maintenance program. We handle all the scheduling for inspections and services, so you never have to worry about missing a maintenance date. Trust us to keep your forklifts in optimal condition, ensuring they run smoothly and safely at all times.
                            </p>
                            <p className="pb-1">
                                <h2 className="font-semibold text-lg">Preferred Maintenance</h2>
                                Upgrade your maintenance experience with our Preferred Maintenance program. Benefit from exclusive discounts on all parts and labor for both repairs and routine maintenance. This program not only keeps your equipment in top shape but also offers significant savings, making it a smart choice for cost-conscious operations.
                            </p>
                            <p className="pb-1">
                                <h2 className="font-semibold text-lg">Preferred Maintenance Plus</h2>
                                Achieve cost certainty and operational efficiency with our Preferred Maintenance Plus (PM+) program. This program offers fixed costs for maintenance services, helping you understand your cost per operating hour. Additionally, enjoy deeper discounts on parts and labor, providing you with a comprehensive and economical maintenance solution that supports your business's financial planning and operational needs.
                            </p>
                        </div>
                        <div className="md:w-2/3 flex items-center justify-center">
                            <img className="object-contain w-full h-auto" src={service2} alt="Service image 2" />
                        </div>
                    </div>
                    <div className="flex flex-wrap md:flex-nowrap gap-5">
                        <div className="md:w-2/3 flex items-center justify-center">
                            <img className="object-contain w-full h-auto" src={service3} alt="Service image 3" />
                        </div>
                        <div className="md:w-1/3 flex flex-col justify-center">
                            <p className="pb-3">
                                <h3 className="font-black text-xl">Battery Service</h3>
                            </p>
                            <p className="pb-3">
                                Ensure your forklifts perform at their best with our comprehensive forklift battery service. At Hurricane Lift Truck, we understand that a well-maintained battery is crucial for the efficiency and longevity of your equipment. Our expert technicians provide thorough inspections, maintenance, and repair services for all types of forklift batteries. From routine check-ups and cleaning to advanced diagnostics and replacements, we cover every aspect to keep your batteries in peak condition.
                                Our battery service is an integral part of each of our main maintenance plans. For those seeking dedicated battery care, we also offer a specialized battery service plan. This plan provides focused attention on battery maintenance, ensuring your power source remains reliable and efficient. Trust us to deliver reliable and efficient battery service, reducing downtime and extending the life of your forklifts, so your operations run smoothly and efficiently.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-wrap md:flex-nowrap gap-5">
                        <div className="md:w-1/3 flex flex-col justify-center">
                            <p className="pb-3">
                                <h3 className="font-black text-xl">Equipment Health Reports</h3>
                            </p>
                            <p className="pb-3">
                                Our Equipment Health Reports provide real-time insights into your equipment's condition, identifying potential issues before they escalate. With clear, actionable data, you can make informed decisions to maintain optimal performance and efficiency. Trust Hurricane Lift Truck for the best in preventative maintenance.
                            </p>
                            {/* <a
                                href="https://www.hurricanelift.com:5000/download-brochure"
                                download
                                className="px-5 py-3 bg-blue-500 text-white font-bold rounded hover:bg-blue-700 transition duration-300"
                            >
                                Download Our PDF Brochure
                            </a> */}
                        </div>
                        <div className="md:w-2/3 flex items-center justify-center">
                            <img className="object-contain w-full h-auto" src={service4} alt="Service image 4" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RepairsAndServicePage;